<template>
  <div class="container" v-if="initOk">
    <div v-if="isFullTime">
      <div class="tip">
        <van-icon class="icon" name="smile" />
        <p>您已是在校住宿就读方式</p>
        <div style="margin-top: 10px">
          <van-button type="default" block @click="back()">返回</van-button>
        </div>
      </div>
    </div>
    <div v-else>
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        注意事项
      </van-divider>
      <div class="info">
        申请在校住宿就读学生将按照广西外国语学院驻校学生的管理体系进行管理，严格考勤和考核。建议不具备在校住宿就读条件的学生慎重考虑后再申请！
      </div>
      <van-button type="default" block @click="fullTimeReq()"
        >确认申请</van-button
      >
      <van-button type="danger" block @click="navToMe()" style="margin-top:10px"
        >暂不申请</van-button
      >
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import { Notify } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      isFullTime: undefined,
    };
  },
  created() {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
    });
    api.fullTimeReq().then((res) => {
      this.isFullTime = res;
      Toast.clear();
      this.initOk = true;
    });
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    fullTimeReq() {
      Dialog.confirm({
        title: "提示",
        message: "您确认申请在校住宿就读？",
      })
        .then(() => {
          api.insertFullTimeReq().then((res) => {
            if (res.state == "ok") {
              Toast.success("申请成功");
              this.$router.push({ path: "/me", query: {} });
            } else {
              Notify({ type: "danger", message: res.msg });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    navToMe() {
      this.$router.push({ path: "/me", query: {} });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 10px;
}
.info {
  margin-bottom: 10px;
  padding: 10px;
  text-indent: 2em;
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #ed6a0c;
  background-color: #fffae5;
}
.tip {
  text-align: center;
  margin: 100px 10px 10px 10px;
}
.tip .icon {
  font-size: 60px;
  color: #c12b35;
}
.tip p {
  margin-bottom: 20px;
  font-size: 14px;
}
</style>
